import log from '../utils/log';
import DataSource from './data_source';
import {decodeMultiPolygon} from './mvt';
import Geo from '../utils/geo';


/**
    Regular grid source
*/
export class GridSource extends DataSource {
    constructor(source, sources) {
        super(source, sources);        
        this.layerName = 'grid';
        this.tile_indexes = {}; // geojson-vt tile indices, by layer name
        this.setTileSize(512); // auto-tile to 512px tiles for fewer internal tiles
        this.pad_scale = 0; // we don't want padding on auto-tiled sources

        this.tile_grid_depth = this.config.tile_grid_depth || 2; // Tile grid depth = number of tile subdivisions to apply to draw grid lines
        this.cell_width = 4096; // In local cell coordinates system
        this.cell_ticks = this.getCellTicks();
    }

    _load(dest) {
        dest.source_data.layers[this.layerName] = this.getGridCellFeatures();

        return Promise.resolve(dest);
    }

    getCellTicks() {
        const cell_ticks = [];

        for(let depth = 0; depth < this.tile_grid_depth; depth++) {
            const ticks = (depth > 1 ? 2**(depth - 1) : 1);
            const delta = (depth > 0 ? this.cell_width / ticks : 0);
            const shift = delta / 2;

            for(let tick = 0; tick < ticks; tick++) {
                const pos = shift + delta * tick;
                cell_ticks.push({
                    properties: {
                        depth: depth,
                        tick: tick,
                    },
                    pos: pos
                });
            }
        }

        return cell_ticks;
    }

    getGridCellFeatures() {
        // Convert from MVT-style JSON struct to GeoJSON
        let collection = {
            type: 'FeatureCollection',
            features: []
        };

        for(const tick of this.cell_ticks) {

            const vline = {
                type: 'Feature',
                geometry: {
                    type: 'MultiLineString',
                    coordinates: [[[tick.pos, 0], [tick.pos, this.cell_width]]]
                },
                properties: Object.assign({ dir: 'v' }, tick.properties)
            }
            
            const hline = {
                type: 'Feature',
                geometry: {
                    type: 'MultiLineString',
                    coordinates: [[[0, tick.pos], [this.cell_width, tick.pos]]]
                },
                properties: Object.assign({ dir: 'h' }, tick.properties)
            }
            
            collection.features.push(vline);
            collection.features.push(hline);
        }

        return collection;
    }
}

DataSource.register('Grid', () => GridSource);
